import { Component, OnInit, Input } from '@angular/core';
import { UtilityService } from '../../../core/services/utility.service';

@Component({
  selector: 's1-card-box',
  templateUrl: './s1-card-box.component.html',
  styleUrls: ['./s1-card-box.component.scss']
})
export class S1CardBoxComponent implements OnInit {

  @Input() item: IBoxCard;
  @Input() isCurrency: boolean = true;

  constructor( private utils: UtilityService ) { }

  ngOnInit() {
    // if( this.item && this.item.meta ) {
    //   this.item.meta = JSON.parse( this.item.meta );
    // }
    // this.item.meta['lighterColor'] = this.utils.LightenDarkenColor( 0.2 , this.item.meta.color )
    // console.log('LightenDarkenColor - originale' , this.item.meta.color , ' - ' , this.item.meta['lighterColor'] , this.item.meta )
  }

}

export interface IBoxCard {
  id: number,
  description: string, 
  meta: any,
  amount?: number,
  total?: number
}
