import { Injectable } from '@angular/core';

@Injectable()
export class MenuService {

    menuItems: Array<any>;

    constructor() {
        this.menuItems = [];
    }

    addMenu(items: Array<{
        text: string,
        heading?: boolean,
        link?: string,     // internal route links
        elink?: string,    // used only for external links
        target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
        icon?: string,
        alert?: string,
        submenu?: Array<any>,
        visibleTo?: Array<any>,
    }>) {
        items.forEach((item) => {
            this.menuItems.push(item);
        });
    }

    getMenu() {
        return this.menuItems;
    }

    filterMenuVisibility( companyGroupName: string) {
        console.log('filterMenuVisibility - companyGroupName' , companyGroupName)
        let result = [];
        this.menuItems.map( item => {
            if( !item.visibleTo || item.visibleTo.length == 0 || item.visibleTo.some( r => r == companyGroupName ) ) {
                result.push(item);
            } 
        })
        return result;
    }

}
