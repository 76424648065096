<div class="item user-block" *ngIf="userBlockIsVisible()">
    <!-- User picture-->
    <div class="user-block-picture">
        <div class="user-block-status">
            <img class="img-thumbnail rounded-circle" [src]="user.picture" alt="Avatar" />
            <div class="circle bg-success circle-lg"></div>
        </div>
    </div>
    <!-- Name and Job-->
    <div class="user-block-info">
        <span class="user-block-name">{{ 'sidebar.WELCOME' | translate }}  {{ user.name }}</span>
        <span class="user-block-role"> {{ user.role }}</span>
    </div>
    <hr>
    <div class="text-center">
        <p class="text-center">{{ 'sidebar.azienda' | translate | uppercase }}</p>
        <p>
            <span class="text-left">{{company_selected?.businessName}}</span>
            <span class="text-right">
                 <a (click)="classicModal.show()"><em class="fa-1x ml-2 fas fa-pencil-alt" ></em></a>
            </span>
        </p>
    </div>
    <hr>
</div>
<!-- Classc modal -->
<div class="modal fade" bsModal #classicModal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm"  >
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{ 'home.modal_change_company' | translate }}</h4>
                <button type="button" class="close" aria-label="Close" (click)="classicModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" >
                <ng-container *ngFor="let company of companies; let i= index;">
                    <div >
                        <button  data-dismiss="modal" class="btn btn-primary btn-block mb-2" (click)="scegliAzienda(company);classicModal.hide();">{{company.businessName}}</button>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
