import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SpecificErrors } from '../interfaces/common';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';

declare const pSBC: any;

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor (private http: HttpClient, private translate: TranslateService) {}

  getListNotificationType() {
    return [
      { value : 'NE ' , descrizione : 'Notifica Esito solo PA (EC01, EC02)' } , 
      { value : 'NS' , descrizione : 'Notifica Scarto' } , 
      { value : 'MC' , descrizione : 'Mancata Consegna' } , 
      { value : 'RC' , descrizione : 'Ricevuta Consegna' } , 
    ]
  }

  /**
   * Shade, Blend and Convert a Web Color (pSBC.js)
   * https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)#stackoverflow-archive-begin
   * 
   * @param amount Positive value LIGHTEN action - Negative value DARKEN action
   * @param color Auto-detects and accepts standard Hex colors in the form of strings. For example: "#AA6622" or "#bb551144". Auto-detects and accepts standard RGB colors in the form of strings. For example: "rgb(123,45,76)" or "rgba(45,15,74,0.45)"
   * 
   */
  LightenDarkenColor( amount: number , color: string ) {
    // console.log( 'LightenDarkenColor' , pSBC( amount , color ) )
    return pSBC( amount , color );
  }

  searchCompanyData( company ) {
    let data: any;
    let path: string;
    let res;

    if( environment.production ) {
      path = 'assets/data/company-prod.json';
    } else {
      path = 'assets/data/company-dev.json';
    }

    this.http.get(path).subscribe( r => {
      data = r;

      if( !company ) {
        return false;
      }

      res = data.find( x => x.id == company.id );    
      return res;

    });
    
  }

  isLogged(): boolean {
    return localStorage.getItem('token') != null || localStorage.getItem('token') != '';
  }

  manageResponseError(resp: any): void {
    let errorText: string;
    switch(resp.outcome.code) {
      case SpecificErrors.VNV001:
        errorText = this.translate.instant('error.error_vn001')
      break;
      default:
        errorText = resp.outcome.description
      break;
    }

    swal.fire({
      icon: "error",
      text: errorText
    });
  }

}

export function dateFormatting(date) {
  let month = date.getMonth() + 1;
  month = month < 10 ? '0' + month : month;
  let endDate = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  return date.getFullYear() + "-" + month + "-" + endDate
}