import { ECompanyGroupName } from "../core/interfaces/common";

const Tutte = {
    text: 'Tutte',
    link: '/home',
    icon: 'fa mr-2 fas fa-sort-alpha-up'
};

const Accettate = {
  text: 'Accettate',
  link: '/user',
  icon: 'fa mr-2 fas fa-check'
};

const InValidazione = {
    text: 'In validazione',
    link: '/user',
    icon: 'fa mr-2 fas fa-spinner'
};

const Scartate = {
    text: 'Scartate',
    link: '/user',
    icon: 'fa mr-2 fas fa-exclamation'
};

const Elements = {
    text: 'Elements',
    link: '/elements',
    icon: 'icon-chemistry',
    submenu: [
        {
            text: 'Buttons',
            link: '/elements/buttons'
        }
    ]
};

const Home = {
    text: 'Fatture',
    link: '/home',
    icon: 'fa-1x icon-home mr-2',
    translate: 'sidebar.menu.fatture'
};

const Dashboard = {
    text: 'Dashboard',
    link: '/dashboard',
    icon: 'fa-1x icon-speedometer mr-2',
    translate: 'sidebar.menu.dashboard'
};

const CodeDiStampa = {
    text: 'Code di Stampa',
    link: '/code-di-stampa',
    icon: 'fa-1x icon-printer mr-2',
    visibleTo: [ ECompanyGroupName.SACBO ],
    translate: 'sidebar.menu.codeStampa'
}

const Corrispettivi = {
    text: 'Corrispettivi',
    link: '/corrispettivi',
    icon: 'fa-1x icon-layers mr-2',
    visibleTo: [ ECompanyGroupName.ATB ],
    translate: 'sidebar.menu.corrispettivi'
}

const ProfiliFatturazione = {
    text: 'Profili di fatturazione',
    link: '/profilo-fatturazione',
    icon: 'fa-1x icon-doc mr-2',
    submenu: [
        {
            text: 'Riepilogo',
            link: '/profili-fatturazione',
            icon: 'fa-1x icon-speedometer mr-2',
        },
        {
            text: 'Elenco',
            link: '/profili-fatturazione/elenco',
            icon: 'fa-1x icon-doc mr-2',
        }
    ]
};

const headingMain = {
    text: 'Fatture clienti',
    heading: true
};

export const menu = [
    Home,
    Corrispettivi,
    Dashboard,
    CodeDiStampa,
    ProfiliFatturazione
    /*headingMain,
    Tutte,
    Accettate,
    InValidazione,
    Scartate,
    Elements*/
];
