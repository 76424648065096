import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

export interface IS1InputSelectItem {
  code: string | number;
  label: string;
}

@Component({
  selector: 's1-select',
  templateUrl: './s1-select.component.html',
  styleUrls: ['./s1-select.component.scss']
})
export class S1Select implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() label: string = '';
  @Input() labelGrouped: boolean = true;
  @Input() readonly: boolean = false;
  @Input() itemsList: IS1InputSelectItem[];
  @Input() itemDescpription: string;
  @Input() bindAllObject: boolean = true;

  @Output() onSelectClear = new EventEmitter();
  @Output() onSelectChange = new EventEmitter();

  constructor() { }

  ngOnInit(): void {

  }

  checkIsValid(): boolean {

    return this.control.valid || !this.control.touched;

  }

  cleared() {
    this.onSelectClear.emit();
  }

  changed(event) {
    this.onSelectChange.emit(event);
  }

}
