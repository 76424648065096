import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 's1-input-checkbox',
  templateUrl: './s1-input-checkbox.component.html',
  styleUrls: ['./s1-input-checkbox.component.scss']
})
export class S1InputCheckboxComponent implements OnInit {

  @Input() check: boolean = false;
  @Input() control: UntypedFormControl;
  @Input() isValid: boolean = true;
  @Input() readonly: boolean = false;
  @Output() checkChange = new EventEmitter<boolean>()

  constructor() { }

  ngOnInit(): void { }

  checkIsValid(): boolean {
    return this.control.valid || !this.control.touched;
  }

}
