import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 's1-button-check',
  templateUrl: './s1-button-check.component.html',
  styleUrls: ['./s1-button-check.component.scss']
})
export class S1ButtonCheck implements OnInit {

  @Input() check: boolean = false
  @Input() wrapped: boolean = false;
  @Output() checkChange = new EventEmitter<boolean>()

  constructor() { }

  ngOnInit(): void { }

  toggle() {
    this.check = !this.check
    this.checkChange.emit(this.check)
  }

}
