import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserblockService } from './userblock.service';
import { AppService } from '../../../core/services/base.service';
import { SettingsService } from '../../../core/settings/settings.service';

@Component({
	selector: 'app-userblock',
	templateUrl: './userblock.component.html',
	styleUrls: ['./userblock.component.scss']
})



export class UserblockComponent implements OnInit {
	user: any;
	public companies: Array<any>; // = Aziende coinvolte;

	company_selected: any;

	@ViewChild('varName') someElement;


	constructor(private router: Router, public userblockService: UserblockService, public appService: AppService, public settings: SettingsService) {
		this.user = {
			name: localStorage.getItem("name"),
			picture: localStorage.getItem("picture") == null ? 'assets/img/logo-single.png' : localStorage.getItem("picture"),
			role: localStorage.getItem("role")
		};
	}

	ngOnInit() {
		this.caricaAziende();
		setTimeout(() => {
			this.company_selected = this.settings.getAziendaSelezionata();
		});
		
	}


	userBlockIsVisible() {
		return this.userblockService.getVisibility();
	}

	public caricaAziende() {

		this.appService.getElement(this.appService.pathAziende)
			.subscribe((response) => {
				this.companies = response.results;
			},
				(error) => {

				}
			);
	}


	scegliAzienda(company) {
		this.settings.setAziendaSelezionata(company);
		/*this.router.navigate(['location.pathname']);
		this.router.navigate([location.pathname]); */

		setTimeout(() => {
			location.reload();
		},
			100);
	}
}
