<s1-input-label [label]="label" [grouped]="labelGrouped" [needAsterisk]="needAsterisk">
  <input #inputEl class="form-control" type="{{ type }}" [formControl]="control" 
    maxlength="{{maxlength}}" 
    minlength="{{minlength}}" 
    [readonly]="readonly"
    (keypress)="onKeypressEvent($event)" 
    (keyup)="onKeyupEvent($event)"
    (input)="onInputEvent($event)"
    (paste)="onPasteEvent($event)"
    (blur)="onBlurEvent()"
    [tooltip]="popTemplate" 
    triggers=""
    containerClass="error_tooltip"
    #pop="bs-tooltip" 
    [ngClass]="{'text-uppercase': toUppercase}" />
  <span class="spinner-border spinner-border-sm" *ngIf="isLoading" role="status" aria-hidden="true"></span>
</s1-input-label>
<ng-template #popTemplate><div [innerHtml]="htmlTooltip"></div></ng-template>