export enum ETypeRequestArgs {
  HEADER_TOKEN_OPTION = "X-auth-Token",
  HEADER_APIKEY_OPTION = "ApiKey"
}

export enum ECompanyGroupId {
  ATB = 1,
  SACBO = 2,
}

export enum ECompanyGroupName {
  ATB = "ATB",
  SACBO = "SACBO",
}

export enum SpecificErrors {
  VNV001 = "VNV001" // Invalid VAT number
}