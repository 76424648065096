import { Injectable } from '@angular/core';

import { AppService } from './base.service';
import { ETypeRequestArgs } from '../interfaces/common';
import { Observable } from 'rxjs';

const pathMetodiPagamento = 'bo/invoice/paymentModes';
const pathReconciliationStatus = 'reconciliation/status_reconciliation';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor( private baseService: AppService ) { }

  getCustomerDataTypes(): Observable<S1HttpResponseData> {
    return this.baseService.getAll('b2b/cdi/customerdatatypes', ETypeRequestArgs.HEADER_APIKEY_OPTION );
  }

  getComapanyGroupList() {
    return [
      {id: 1, name: 'ATB'},
      {id: 2, name: 'SACBO'}
    ]
  }

  getCompanyGroupNameById( id: number ) {
    return this.getComapanyGroupList().find( item => item.id == id )
  }

  getMetodiPagamento() {
    return this.baseService.getElement( pathMetodiPagamento );
  }

  getReconciliationStatus() {
    return this.baseService.getElement( pathReconciliationStatus );
  }

}

interface S1HttpResponse {
  data?: S1HttpResponseData,
  outcome?: S1HttpResponseOutcome
}
interface S1HttpResponseOutcome {
  code?: any,
  description?: string,
  message?: string,
  success?: boolean
}
interface S1HttpResponseData {
  results?: any,
  total?: number
}