import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
declare var $: any;

@Injectable()
export class SettingsService {

    private user: any;
    private app: any;
    private layout: any;
    private errorTranslation: any;

    constructor(private translate: TranslateService, private router: Router) {
        this.errorTranslation = this.translate.instant('error');
        // User Settings
        // -----------------------------------
        this.user = {
            name: '',
            job: '',
            picture: ''
        };

        // App Settings - footer
        // -----------------------------------
        this.app = {
            name: 'Soluzione 1',
            description: '',
            year: ((new Date()).getFullYear())
        };

        // Layout Settings - basic settings for layout & Co.
        // -----------------------------------
        this.layout = {
            isFixed: true,
            isCollapsed: false,
            isBoxed: false,
            isRTL: false,
            horizontal: false,
            isFloat: false,
            asideHover: false,
            theme: null,
            asideScrollbar: false,
            isCollapsedText: false,
            useFullLayout: false,
            hiddenFooter: false,
            offsidebarOpen: false,
            asideToggled: false,
            viewAnimation: 'ng-fadeInUp'
        };

    }
    
    getVersionSetting() {
      return environment.version;
    }

    getAppSetting(name) {
        return name ? this.app[name] : this.app;
    }
    getUserSetting(name) {
        return name ? this.user[name] : this.user;
    }
    getLayoutSetting(name) {
        return name ? this.layout[name] : this.layout;
    }

    setAppSetting(name, value) {
        if (typeof this.app[name] !== 'undefined') {
            this.app[name] = value;
        }
    }
    setUserSetting(name, value) {
        if (typeof this.user[name] !== 'undefined') {
            this.user[name] = value;
        }
    }

    setAziendaSelezionata(companyObj) {
      localStorage.setItem("company_selected",JSON.stringify(companyObj));
    }

    getAziendaSelezionata() {
      return JSON.parse(localStorage.getItem("company_selected"));
    }

    setLayoutSetting(name, value) {
        if (typeof this.layout[name] !== 'undefined') {
            return this.layout[name] = value;
        }
    }

    toggleLayoutSetting(name) {
        return this.setLayoutSetting(name, !this.getLayoutSetting(name));
    }

    sessionExpired() {
      localStorage.clear();
    }

    manageErrorMsg(outcome) {
      let message = "";
      switch (outcome.code) {
        case '0001':
          message = this.errorTranslation.error_0001;
        break;
        case '0002':
          message = this.errorTranslation.error_0002;
        break;
        case '0003':
          message = this.errorTranslation.bad_credential;
        break;
        case '0004':
          message = this.errorTranslation.wrong_password;
        break;
        case '0005':
          this.sessionExpired();
          this.router.navigate(["/login/0"]);
        break;
        case '0006':
          message = this.errorTranslation.account_disabled;
        break;
        case '0007':
          this.sessionExpired();
          this.router.navigate(["/login/0"]);
          message = this.errorTranslation.auth_required;
        break;
        default:
          message = this.errorTranslation.generic_error;
        break;
      }
      return message;
    }


    stringToServerFormat(StringData) {
        var year;
        var month;
        var day;
        var finalFormat;

        year = StringData.substring(6,10);
        month = StringData.substring(3,5);
        day = StringData.substring(0,2);

        finalFormat = year +"-"+month +"-"+day;

        return finalFormat;
    }

    stringTotalFromDate(date) {
        const d = new Date(date);
        console.log("DATA iniziale", d);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();
      
        if((d.getMonth() + 1) < 10  ) {
            month = "0" + month;
        }
      
        if((d.getDate()) < 10  ) {
          day = "0" + day;
        }
        console.log("DATA FORMATTATA", year +"-" + month + "-" +day);
        return year +"-" + month + "-" + day;
      }



  mockAziende() {
    if (localStorage.getItem("mockAziende")) {
      console.log("da get item", "");
      return JSON.parse(localStorage.getItem("mockAziende") );
    } else {
      console.log("da base", "");
      const response = new Array(  
        {  
            companyCode:'900340',
            companyName:'CALBER S.R.L.' 
        },
        {  
            companyCode:'900340',
            companyName:'ATB MOBILE S.R.L.' 
        },
        {  
            companyCode:'900340',
            companyName:'ALTRO ATB' 
        });

        localStorage.setItem("mockOrders",JSON.stringify(response));
        return response;
    }

  }
}
