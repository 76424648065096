import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { LoggingService } from './log.service';
import { throwError } from 'rxjs';
import { SettingsService } from '../settings/settings.service';
import { ETypeRequestArgs } from '../interfaces/common';


@Injectable()
export class AppService {

  // PATH TO USE
  public pathListFatture = "bo/invoice/invoices";
  public pathAziende = "bo/invoice/companies";
  public pathMetodiPagamento = 'bo/invoice/paymentModes';
  public pathApplicazioni = 'bo/invoice/sourceApplications';
  public pathDownloadPDFMin = 'bo/invoice/downloadacube/';
  public pathDownloadPDFCustomer = 'bo/invoice/download/';
  public pathDownloadPDFStd = 'bo/invoice/downloadacube/';
  public pathNewInvoiceCreditNote = 'bo/invoice/createcreditnote/';
  public pathMassivePrint = 'bo/invoice/downloadinvoices';
  public pathDetailCreditNote = 'bo/invoice/createcreditnotes/';
  public pathListDashboard = "bo/statistics";
  public pathListDashboardPaymentChannel = "bo/statistics/paymentChannel";
  public pathListDashboardServiceItems = "bo/statistics/serviceItem";
  public pathExportFatture = "bo/invoice/invoices/export";
  
  
  public paging = true;
  public itemsPerPage = environment.rows; // server pagination
  public page: number = 1;
  public numPages = 1;
  public totalItems = 1;

  constructor(private http: HttpClient, private logger: LoggingService, private router: Router, private settings: SettingsService) {}

  // Rest Items List Service
  getList(path, filters) {
    // stringa per chiamata da fare
    let queryStr = "";
    let i = 0;
    let stringAmp = "";
    // imposto i parametri del filtro
    if (filters != null) {
      Object.keys(filters).forEach(function (key) {
        if (filters[key] != null &&   filters[key].toString() !== "") {
          if (i > 0) { stringAmp = "&"; }
          queryStr = queryStr + stringAmp + key + "=" + filters[key];
          i++;
        }
      });
    }

    // creo tutta la chiamata completa di paginazione e righe
    queryStr = environment.restBaseUrl
    + path + "?" + queryStr
    + "&paging=" + this.paging
    + "&page=" + this.page
    + "&rows=" + this.itemsPerPage;


    // clean url
    queryStr = queryStr.replace("?&", "?");

    this.logger.log("Chiamata URL lista:" + queryStr, "", 200);

    return this.http
      .get<any[]>(queryStr, this.getRequestOptionArgs(null,null))
      .pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const data = response['data'];
        // this.totalItems = data['total'];

        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return data;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
      
        const res: any = errorResponse;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  // Rest Items Service: Read one element (detail)
  getElement(path) {
    path = environment.restBaseUrl + path;
    return this.http
      .get<any[]>(path, this.getRequestOptionArgs(null,null))
      .pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        // this.totalItems = dataResponse['total'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        const res: any = errorResponse;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  // Rest Items Service: Read all REST Items
  getAll(path, typeRequestArgs: ETypeRequestArgs = ETypeRequestArgs.HEADER_TOKEN_OPTION ) {
    path = environment.restBaseUrl + path;
    let reqArg;

    switch (typeRequestArgs) {
      case ETypeRequestArgs.HEADER_APIKEY_OPTION:
        reqArg= this.getRequestOptionArgsFatturAttiva( null );
        break;
      case ETypeRequestArgs.HEADER_TOKEN_OPTION:
      default:
        reqArg= this.getRequestOptionArgs( null, null );
        break;
    }

    return this.http
      .get<any[]>(path, reqArg )
      .pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        // this.totalItems = dataResponse['total'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
      const res: any = errorResponse;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  // get element list by data passed
  getAllFromData(path, requestData): any {
    path = environment.restBaseUrl + path;
    return this.http
      .post<any[]>(path, requestData, this.getRequestOptionArgs(null,null))
      .pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log("Error Response:", errorResponse, 200);
        const res: any = errorResponse;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  // creation of new element
  newElement(path, elementRequest): any {
    path = environment.restBaseUrl + path;
    return this.http
      .post<any[]>(path, elementRequest, this.getRequestOptionArgs(null,null))
      .pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return response;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          response['outcome'] = outcome;
          return response;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log("Error Response:", errorResponse, 200);
        const res: any = errorResponse;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  // modify of an element
  editElement(path, elementRequest): any {
    path = environment.restBaseUrl + path;
    return this.http
      .put<any[]>(path, elementRequest, this.getRequestOptionArgs(null,null))
      .pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return response;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log("Error Response:", errorResponse, 200);
        const res: any = errorResponse;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  // modify of an element
  deleteElement(path, elementRequest): any {
    path = environment.restBaseUrl + path;
    return this.http
      .put<any[]>(path, elementRequest, this.getRequestOptionArgs(null,null))
      .pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return response;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log("Error Response:", errorResponse, 200);
        const res: any = errorResponse;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  // Rest Items Service: Read all REST Items
  getPDF(path,typeRequestArgs,filters,noPagination) {

    if(filters != null){
      // stringa per chiamata da fare
      let queryStr = "";
      let i = 0;
      let stringAmp = "";
      // imposto i parametri del filtro
      if (filters != null) {
        Object.keys(filters).forEach(function (key) {
          if (filters[key] != null &&   filters[key].toString() !== "") {
            if (i > 0) { stringAmp = "&"; }
            queryStr = queryStr + stringAmp + key + "=" + filters[key];
            i++;
          }
        });
      }

      if(!noPagination) {
        // creo tutta la chiamata completa di paginazione e righe
        queryStr = path + "?" + queryStr
        // + "&paging=" + this.paging
        // + "&page=" + this.page
        // + "&rows=" + this.itemsPerPage;
      }else{
        queryStr = path + "?" + queryStr;
      }

      // clean url
      path = queryStr.replace("?&", "?");

    }


    var reqArg;
    switch(typeRequestArgs){
      case 1:
        reqArg= this.getRequestOptionArgs('blob','application/pdf');
      break;
      case 2:
        reqArg= this.getRequestOptionArgsFatturAttiva('blob');
      break;
    }
    path = environment.restBaseUrl + path;
    return this.http
      .get<any[]>(path, reqArg)
      .pipe(map(
      (response: any) => {
        return new Blob([response], { type: 'application/pdf' });
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
      console.log("Errore " + errorResponse.message);
      const res: any = errorResponse;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }


  invite(idUser: number): any {
    const path = environment.restBaseUrl + '/admin/user/invite/' + idUser;
    const elementRequest: any = {};

    // TODO: inserire la chiamata al servizio corretto!
    return this.http
      .put<any[]>(path, elementRequest, this.getRequestOptionArgs(null,null))
      .pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return response;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log("Error Response:", errorResponse, 200);
        const res: any = errorResponse;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  // Rest Items Service: Read all REST Items
  getCSV(path,typeRequestArgs,filters,noPagination) {

    if(filters != null){
      // stringa per chiamata da fare
      let queryStr = "";
      let i = 0;
      let stringAmp = "";
      // imposto i parametri del filtro
      if (filters != null) {
        Object.keys(filters).forEach(function (key) {
          if (filters[key] != null &&   filters[key].toString() !== "") {
            if (i > 0) { stringAmp = "&"; }
            queryStr = queryStr + stringAmp + key + "=" + filters[key];
            i++;
          }
        });
      }

      if(!noPagination) {
        // creo tutta la chiamata completa di paginazione e righe
        queryStr = path + "?" + queryStr
        // + "&paging=" + this.paging
        // + "&page=" + this.page
        // + "&rows=" + this.itemsPerPage;
      }else{
        queryStr = path + "?" + queryStr;
      }

      // clean url
      path = queryStr.replace("?&", "?");

    }


    var reqArg;
    switch(typeRequestArgs){
      case 1:
        // reqArg= this.getRequestOptionArgs('blob','application/vnd.openxmlformats-ficedocument.spreadsheetml.sheet');
        reqArg= this.getRequestOptionArgs('blob', null);
      break;
      case 2:
        reqArg= this.getRequestOptionArgsFatturAttiva('blob');
      break;
    }
    path = environment.restBaseUrl + path;
    return this.http
      .get<any[]>(path, reqArg)
      .pipe(map(
      (response: any) => {
        // return new Blob([response], { type: 'application/vnd.openxmlformats-ficedocument.spreadsheetml.sheet' });
        return response;
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
      console.log("Errore " + errorResponse.message);
      const res: any = errorResponse;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  getRequestOptionArgs(responseTypology, contentType): any {
    console.log("getRequestOptions: " + responseTypology + ", contentype:" + contentType);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',//contentType === null ? 'application/json' : responseTypology,
        'X-Auth-Token': localStorage.getItem("token") != null ? localStorage.getItem("token") : ''
        
      }),
      responseType:responseTypology === null ? 'json' : responseTypology
    };
    return httpOptions;
  }
  
  getRequestOptionArgsFatturAttiva(responseTypology): any {
    console.log("getRequestOptionsFatturAttiva: " + responseTypology);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/pdf',
        'ApiKey': environment.apiKeyFatturAttiva,
      }), 
      responseType:responseTypology === null ? 'json' : responseTypology
    };
    return httpOptions;
  }
}

