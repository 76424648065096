<!-- START card-->
<div class="card flex-row align-items-center align-items-stretch border-0">
  <div class="col-4 d-flex align-items-center bg-warning-dark justify-content-center rounded-left" [ngStyle]="{'background-color': item.meta.color}">
    <em class="fa-3x" [ngClass]="item.meta.icon ? item.meta.icon : ''"></em>
  </div>
  <div class="col-8 py-3 rounded-right text-white text-right" [ngStyle]="{'background-color': item.meta.lighterColor}">
    <div class="text-uppercase">{{ item?.description }}</div>
    <div class="h2 mt-0 ">
      {{item?.amount | number : (isCurrency ? '1.2-2' : '1.0-0') :'it'}} {{ isCurrency ? '€' : '' }}
      <span *ngIf="item?.total"> / {{ item?.total }}</span>
    </div>
  </div>
</div>