
<ng-container *ngIf="wrapped">
  <button type="button" class="btn" (click)="toggle()">
    <i *ngIf="!check" class="far fa-square fa-2x"></i>
    <i *ngIf="check" class="fa fa-check-square text-primary fa-2x"></i>
  </button>
</ng-container>
<ng-container *ngIf="!wrapped">
  <div class="" (click)="toggle()">
    <i *ngIf="!check" class="far fa-square fa-2x"></i>
    <i *ngIf="check" class="fa fa-check-square text-primary fa-2x"></i>
  </div>
</ng-container>
