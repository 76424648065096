import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TooltipDirective } from 'ngx-bootstrap/tooltip';

@Component({
  selector: 's1-input-text',
  templateUrl: './s1-input-text.component.html',
  styleUrls: ['./s1-input-text.component.scss']
})
export class S1InputText implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() label: string = '';
  @Input() labelGrouped: boolean = true;
  @Input() readonly: boolean = false;
  @Input() type: string = 'text';
  @Input() isValid: boolean = true;
  @Input() isLoading: boolean = false;
  @Input() maxlength: number;
  @Input() minlength: number;
  @Input() needAsterisk: boolean = false;
  @Input() stripSpecialChar: boolean = false;
  @Input() toUppercase : boolean = false;

  @Output() input = new EventEmitter<any>();
  @Output() keypress = new EventEmitter<any>();

  @ViewChild('inputEl', { static: true }) inputEl: ElementRef;
  @ViewChild('pop', { static: true }) pop: TooltipDirective;

  htmlTooltip: string;

  constructor( private translate: TranslateService) { 
    this.htmlTooltip = "<span>"+ this.translate.instant('profilo_fatturazione.form.tooltip.caratteri_ammessi') +"</span>";
    this.htmlTooltip += "<ul>";
    this.htmlTooltip += "<li>"+ this.translate.instant('profilo_fatturazione.form.tooltip.lettere_minuscole')+"</li>";
    this.htmlTooltip += "<li>"+ this.translate.instant('profilo_fatturazione.form.tooltip.lettere_maiuscole')+"</li>";
    this.htmlTooltip += "<li>"+ this.translate.instant('profilo_fatturazione.form.tooltip.numeri')+"</li>";
    this.htmlTooltip += "</ul>";
    
  }

  ngOnInit(): void { }

  checkIsValid(): boolean {

    return this.control.valid || !this.control.touched;

  }

  getFocused() {

    setTimeout(()=> {
      // this.inputEl?.nativeElement.focus();
    }, 1);
    
  }

  onKeypressEvent( event ) {
    this.keypress.emit(event);
    // console.log('onKeypressEvent - event ' , event);
    if( this.stripSpecialChar )
      return this.filterSpecialCharacter( event );
  }

  onKeyupEvent( event ) {
    // const val = (this.control.value) as string;
    // this.control.setValue( val.toUpperCase() );
    // console.log('onKeyupEvent - event ' , event, 'this.control.value' , this.control.value);
    // if( this.stripSpecialChar )
      // return this.filterSpecialCharacter( event );
  }

  onInputEvent( event ) {
    this.input.emit(event);
    // if( this.stripSpecialChar )
    //   return this.filterSpecialCharacter( event );
  }

  filterSpecialCharacter( event , inputName?: string ) {
    var k;  
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    if( (k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || k == 39 || (k >= 48 && k <= 57) ) {
      this.pop.hide();
      return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || k == 39 || (k >= 48 && k <= 57)); 
    } else {
      this.pop.show();
      return false;
    }
  }

  onBlurEvent() {
    this.pop.hide();
  }

  onPasteEvent( event ) {
    // https://stackoverflow.com/a/50138994
    let clipboardData = event.clipboardData;
    let pastedText:string = clipboardData.getData('text');
    console.log('onPaste')
    var k;
    for( let i= 0; i< pastedText.length; i++) {
      k = pastedText.charCodeAt(i);;  //         k = event.keyCode;  (Both can be used)
      if( (k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || k == 39 || (k >= 48 && k <= 57) ) {
        this.pop.hide();
      } else {
        this.pop.show();
        return false;
      }
    }
    return pastedText;
  }

}
