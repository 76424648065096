import { Routes } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';
import { LayoutBlankComponent } from '../layout_blank/layout_blank.component';

export const routes: Routes = [

  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
      { path: 'user', loadChildren: () => import('./users/users.module').then(m => m.UsersModule) },
      { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
      { path: 'code-di-stampa', loadChildren: () => import('./code-di-stampa/code-di-stampa.module').then(m => m.CodeDiStampaModule)},
      { path: 'corrispettivi', loadChildren: () => import('./corrispettivi/corrispettivi.module').then(m => m.CorrispettiviModule) },
      { path: 'profili-fatturazione', loadChildren: () => import('./profili-fatturazione/profili-fatturazione.module').then(m => m.ProfiliFatturazioneModule) },
    ]
  },

  {
    path: '',
    component: LayoutBlankComponent,
    children: [
      { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
      { path: 'login/:id', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
      { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
      { path: 'recover', loadChildren: () => import('./pages/recover/recover.module').then(m => m.RecoverModule) },
      { path: 'activate/:id', loadChildren: () => import('./pages/activate/activate.module').then(m => m.ActivateModule) },
      { path: 'lock', loadChildren: () => import('./pages/lock/lock.module').then(m => m.LockModule) },
      { path: '404', loadChildren: () => import('./pages/error404/error404.module').then(m => m.Error404Module) },
      { path: '500', loadChildren: () => import('./pages/error500/error500.module').then(m => m.Error500Module) },

      { path: 'profilo-fatturazione', loadChildren: () => import('./profili-fatturazione/profili-fatturazione.module').then(m => m.ProfiliFatturazioneModule) },
    ]
  },

  // Not found
  { path: '**', redirectTo: '404' },

];
